/* Card: Promo Box
-------------------------------------------------- */

.promo-box {
    display: grid;
    grid-column-gap: 5px;
}

.promo-box .card-container {
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: 33% 65%;
}

.promo-box .card .card-headline h3 {
    font-size: .95em;
    line-height: 16px;
}
    
.promo-box .tnt-headline a {
    font-weight: bold;
}

.promo-box .card-panel {
    box-shadow: none;
    border: none;
    border-radius: 0;
}

.promo-box .panel-body .card.showcase {
    border: 0;
    padding: 0;
    margin: 0;
}

.promo-box.image-sm .card.showcase .card-container > .card-image,
.promo-box.image-md .card.showcase .card-container > .card-image {
    margin-bottom: 0px;
}

@media only screen and (min-width : 991px) {

    .promo-box {
        padding: 10px 0;
    }

    .promo-box .card-container {
        padding: 0 10px;
        grid-column-gap: 10px;
    }
    
    .promo-box.image-md .card-container {
        grid-template-columns: 47% 53%;
    }
    
    .promo-box.image-lg .card-container {
        grid-template-columns: 100%;
    }
}

@media only screen and (max-width : 990px) {
    .promo-box {
        width: auto;
        white-space: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        margin: 0 -15px 10px -15px;
        padding: 15px 0 0 15px;
        position: relative;
        scrollbar-width: none;
    }
    
    .promo-box::-webkit-scrollbar {
        display: none;
    }
    
    .promo-box .card {
        width: 18em;
    }
    
    .promo-box .card-headline {
        white-space: normal;
    }
}